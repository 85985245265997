import React from 'react';
import {Link} from 'gatsby';
import ScreenshotMonth from '../../assets/image/example_month.png';
import ScreenshotAgenda from '../../assets/image/example_agenda.png';
import Screenshot1WeekPage from '../../assets/image/example_week_columns.png';
import Screenshot1WeekPageWithColorSidebar from '../../assets/image/example_week_columns_with_color_sidebars.png';
import Screenshot1WeekPageWithTaskList from '../../assets/image/2020/week_columns_with_task_list.png';
import Screenshot1DayPage from '../../assets/image/example_1_day.png';
import Screenshot6MonthsPage from '../../assets/image/example_6_month.png';
import ScreenshotYear from '../../assets/image/example_year.png';
import ScreenshotYearMonthsAsColumns from '../../assets/image/2020/year_month_columns.png';
import ScreenshotYearMonthsAsRows from '../../assets/image/2020/year_month_rows.png';
import ScreenshotYearPhotoCalendar from '../../assets/image/example_year_photo_calendar.png';
import Screenshot2MonthsPage from '../../assets/image/example_2_month.png';
import ScreenshotGanttChart from '../../assets/image/example_gantt_chart.png';
import ScreenshotMultiWeek from '../../assets/image/example_multiweek.png';
import ScreenshotCalendarColumns from '../../assets/image/example_calendar_columns.png';
import ScreenshotMultipleMonthsAsColumns from '../../assets/image/example_month_columns.png';
import ScreenshotMultipleMonthsAsRows from '../../assets/image/example_month_rows.png';
import ScreenshotFormLetter from '../../assets/image/example_form_letter.png';
import Screenshot1DayPageWithTaskList from '../../assets/image/example_1_day_with_task_list.png';
import Screenshot1DayPageWithTimeslotColumns from '../../assets/image/example_1_day_timeslot_columns.png';
import Screenshot1DayPageWithWeekGrid from '../../assets/image/example_1_day_with_week_grid.png';
import Screenshot1DayPageWithoutOverlay from '../../assets/image/example_1_day_no_overlay.png';
import Screenshot4DaysPage from '../../assets/image/example_4_day.png';
import ScreenshotList from '../../assets/image/example_list.png';
import ScreenshotMonthSpanningMultiplePages from '../../assets/image/example_month_span_multiple_pages.png';
import ScreenshotMonthWeekdaysOnly from '../../assets/image/example_month_weekdays_only.png';
import Screenshot12MonthsPage from '../../assets/image/example_12_months.png';
import Screenshot1WeekPageColumnWeekdaysOnly from '../../assets/image/example_week_weekdays_only.png';
import Screenshot2WeeksPageColumn from '../../assets/image/example_2_week_columns.png';
import Screenshot1WeekPageGrid from '../../assets/image/example_week_grid.png';
import Screenshot1WeekPageGridWithTaskList from '../../assets/image/2020/week_grid_with_task_list.png';
import Screenshot2WeeksPageGrid from '../../assets/image/example_2_week.png';
import Screenshot2Years from '../../assets/image/2020/2_years_side_by_side.png';
import Screenshot3Years from '../../assets/image/2020/3_years_stacked.png';
import Screenshot4Years from '../../assets/image/2020/4_years.png';

export const SCREENSHOTS = [
	{
		title: 'Month',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Month-1-Month-Page">
					Month template
				</Link>{' '}
				generates a whole month per page, using a traditional monthly calendar
				layout. Events and tasks will be ordered within each day based on
				starting time. All-day events will be placed at the top of each day
				cell.
			</p>
		),
		image: ScreenshotMonth,
	},
	{
		title: 'Agenda',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Agenda">
					Agenda template
				</Link>{' '}
				generates an agenda-style listing of events or tasks for a specified
				date range. This template includes an option to show detailed
				descriptions.
			</p>
		),
		image: ScreenshotAgenda,
	},
	{
		title: '1 Week/Page',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-Columns">
					1 Week/Page template
				</Link>{' '}
				generates a calendar showing 7 days on a single page. Each day will be a
				column, with all-day events at the top and timeslot rows below. Events
				and tasks having start/end times will be placed within their associated
				timeslot cells.
			</p>
		),
		image: Screenshot1WeekPage,
	},
	{
		title: '1 Week/Page with Color Sidebars',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-Columns-With-Color-Sidebars">
					1 Week/Page with Color Sidebars template
				</Link>{' '}
				generates a calendar showing 7 days on a single page. Each day will be a
				column, with all-day events at the top and timeslot rows below. Events and tasks having start/end times will have color-coded sidebars within their associated timeslot cells, and descriptive text shown to the right of the sidebars.
			</p>
		),
		image: Screenshot1WeekPageWithColorSidebar,
	},
	{
		title: '1 Week/Page with Task List',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-Columns-With-Task-List">
					1 Week/Page with Task List template
				</Link>{' '}
				generates a calendar showing 7 days on a single page. Each day will be a column, with all-day events at the top and timeslot rows below. Events that have start/end times will be placed within their associated timeslot cells. Tasks that are overdue or due within the selected week will be listed to the right of the day columns.
			</p>
		),
		image: Screenshot1WeekPageWithTaskList,
	},
	{
		title: '1 Day/Page',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Day-1-Day-Page">
					1 Day/Page template
				</Link>{' '}
				generates a calendar showing 1 day per page. Events and tasks will be
				placed within their corresponding time slots. Overlapping items will be
				shown side-by-side.
			</p>
		),
		image: Screenshot1DayPage,
	},
	{
		title: '6 Months/Page',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Month-6-Months-Page">
					6 Months/Page template
				</Link>{' '}
				generates 6 months to a single page. Dates can be color-coded based on
				the first event or task. Events and tasks will also be listed to the
				right of each month.
			</p>
		),
		image: Screenshot6MonthsPage,
	},
	{
		title: 'Year',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Year">
					Year template
				</Link>{' '}
				template generates a calendar showing an entire year on a single page.
				Each day can be color-coded based on the first event or task occurring
				on that date.
			</p>
		),
		image: ScreenshotYear,
	},
   {
      title: 'Year - Months as Columns',
      description: (
         <p>
            The{' '}
            <Link to="/Documentation/Create-Calendar/Templates-and-Options/Year-Months-As-Columns">
               Year - Months as Columns template
            </Link>{' '}
            generates a calendar consisting of 12 months, where each month is defined as a column and the days of the month are defined in rows. This layout can be used to generate an entire year to a single page. Dates that contain events will be color-coded for up to 5 events.
         </p>
      ),
      image: ScreenshotYearMonthsAsColumns,
   },
   {
      title: 'Year - Months as Rows',
      description: (
         <p>
            The{' '}
            <Link to="/Documentation/Create-Calendar/Templates-and-Options/Year-Months-As-Rows">
               Year - Months as Rows template
            </Link>{' '}
            generates a calendar consisting of 12 months, where each month is defined as a row and the days of the month are defined in columns. This layout can be used to generate an entire year to a single page. Dates that contain events will be color-coded to match the first event.
         </p>
      ),
      image: ScreenshotYearMonthsAsRows,
   },
	{
		title: 'Year Photo Calendar',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Year-Photo-Calendar">
					Year Photo template
				</Link>{' '}
				generates a photo calendar for a whole year. Each month will be printed
				to a single page in portrait orientation, with a photo on the top half
				of the page.
			</p>
		),
		image: ScreenshotYearPhotoCalendar,
	},
	{
		title: '2 Months/Page',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Month-2-Months-Page">
					2 Months/Page template
				</Link>{' '}
				generates 2 months per page, with the first month on the left side of
				the page and the second month on the right side. The page can be cut in
				half and placed in a binder.
			</p>
		),
		image: Screenshot2MonthsPage,
	},
	{
		title: 'Gantt Chart',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Gantt-Chart">
					Gantt Chart template
				</Link>{' '}
				generates a calendar that is formatted as a Gantt chart. Each task will
				be ordered based on when it is schedule to start, with a timeline
				showing the task duration and due date. Tasks that don't have a duration
				will be indicated using a diamond shape.
			</p>
		),
		image: ScreenshotGanttChart,
	},
	{
		title: 'Multi-week',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-Multiweek">
					Multi-Week template
				</Link>{' '}
				generates multiple weeks similar to the default Month template, but
				showing between 1 and 52 weeks instead of only whole months.
			</p>
		),
		image: ScreenshotMultiWeek,
	},
	{
		title: 'Calendar Columns',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Day-Calendar-Columns">
					Calendar Columns template
				</Link>{' '}
				generates a calendar with days as rows and calendar data sources as
				columns. Any number of days can be generated. Each day will consist of
				multiple rows for containing its events.
			</p>
		),
		image: ScreenshotCalendarColumns,
	},
	{
		title: 'Multiple Months as Columns',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Month-Multiple-Months-as-Columns">
					Multiple Months as Columns template
				</Link>{' '}
				generates multiple months, where each month is defined as a column and
				the days of the month are defined in rows. This layout can be used to
				generate an entire year to a single page while still showing item
				details.
			</p>
		),
		image: ScreenshotMultipleMonthsAsColumns,
	},
	{
		title: 'Multiple Months as Rows',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Month-Multiple-Months-as-Rows">
					Multiple Months as Rows template
				</Link>{' '}
				generates multiple months, where each month is defined as a row and the
				days of the month are defined in columns. This layout can be used to
				generate an entire year to a single page while still showing item
				details.
			</p>
		),
		image: ScreenshotMultipleMonthsAsRows,
	},
	{
		title: 'Form Letter',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Form-Letter">
					Form Letter template
				</Link>{' '}
				generates a form letter using the data from scheduling software such as
				Google Calendar, Outlook, and VueMinder. The concept is similar to "mail
				merge", except instead of merging address book data into a form letter,
				it merges calendar data. An example template is provided and can be
				fully customized. The template uses placeholder tags which are replaced
				with event data when form letters are generated.
			</p>
		),
		image: ScreenshotFormLetter,
	},
	{
		title: '1 Day/Page with Task List',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Day-1-Day-Page-with-Task-List">
					1 Day/Page with Task List template
				</Link>{' '}
				generates a calendar showing 1 day per page with a task list on the
				right side of the page. Events will be placed within their corresponding
				time slots. Overlapping events will be shown side-by-side.
			</p>
		),
		image: Screenshot1DayPageWithTaskList,
	},
	{
		title: '1 Day/Page with Timeslot Columns',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Day-1-Day-Page-with-Timeslot-Columns">
					1 Day/Page with Timeslot Columns template
				</Link>{' '}
				generates a calendar showing 1 day per page. Individual calendars will
				be shown as rows and timeslots will be shown as columns.
			</p>
		),
		image: Screenshot1DayPageWithTimeslotColumns,
	},
	{
		title: '1 Day/Page with Week Grid',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Day-1-Day-Page-with-Week-Grid">
					1 Day/Page with Week Grid template
				</Link>{' '}
				generates a calendar showing 1 day per page with a week grid on the
				right side of the page. Events will be placed within their corresponding
				time slots. Overlapping events will be shown side-by-side.
			</p>
		),
		image: Screenshot1DayPageWithWeekGrid,
	},
	{
		title: '1 Day/Page without Overlay',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Day-1-Day-Page-Without-Overlay">
					1 Day/Page without Overlay template
				</Link>{' '}
				generates a calendar showing 1 day per page. Events will be placed
				within their corresponding time slot rows and within the associated
				calendar column.
			</p>
		),
		image: Screenshot1DayPageWithoutOverlay,
	},
	{
		title: '4 Days/Page',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Day-4-Days-Page">
					4 Days/Page template
				</Link>{' '}
				generates a calendar showing 4 days per page. Days will each be within a
				column, with all-day events at the top and time slots below. Events and
				tasks will be placed within their associated time slot cells.
			</p>
		),
		image: Screenshot4DaysPage,
	},
	{
		title: 'List',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/List">
					List template
				</Link>{' '}
				generates a basic list of events or tasks for a specified date range,
				optionally including events or tasks that have no associated date.
			</p>
		),
		image: ScreenshotList,
	},
	{
		title: 'Month Spanning Multiple Pages',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Month-Span-Multiple-Pages">
					Month Spanning Multiple Pages template
				</Link>{' '}
				generates a month that can span multiple pages, depending on the page
				size and amount of space required.
			</p>
		),
		image: ScreenshotMonthSpanningMultiplePages,
	},
	{
		title: 'Month - Weekdays Only',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Month-Weekdays-Only">
					Month Weekdays Only template
				</Link>{' '}
				generates a whole month per page, using a traditional monthly calendar
				layout containing weekdays only. Weekend dates will be excluded. Events
				and tasks will be ordered within each day based on starting time.
				All-day events will be placed at the top of each day cell.
			</p>
		),
		image: ScreenshotMonthWeekdaysOnly,
	},
	{
		title: '12 Months/Page',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Month-12-Months-Page">
					12 Months/Page template
				</Link>{' '}
				generates 12 months to a single page. This is similar to the Year
				template, except a starting month can be specified. Also, dates can
				include color-coding for up to 4 items, instead of only a single item.
			</p>
		),
		image: Screenshot12MonthsPage,
	},
	{
		title: '1 Week/Page Column Weekdays Only',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-Weekdays-Only">
					1 Week/Page Column Weekdays Only template
				</Link>{' '}
				generates a calendar showing weekdays a single page. Each weekday will
				be a column, with all-day events at the top and timeslot rows below.
				Events and tasks having start/end times will be placed within their
				associated timeslot cells.
			</p>
		),
		image: Screenshot1WeekPageColumnWeekdaysOnly,
	},
	{
		title: '2 Weeks/Page Column',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-Columns-2-Weeks-Page">
					2 Weeks/Page Column template
				</Link>{' '}
				generates 2 weeks to a single page, with one week on the top of the page
				and one on the bottom. Days will be shown as columns containing time
				slots.
			</p>
		),
		image: Screenshot2WeeksPageColumn,
	},
	{
		title: '1 Week/Page Grid',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-Grid">
					1 Week/Page Grid template
				</Link>{' '}
				generates a calendar showing 7 days on a single page, arranged in a 2x3
				grid layout. Each event or task will be ordered based on its starting
				time. All-day events will be placed at the top of each day cell.
			</p>
		),
		image: Screenshot1WeekPageGrid,
	},
   {
      title: '1 Week/Page Grid with Task List',
      description: (
         <p>
            The{' '}
            <Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-Grid-With-Task-List">
               1 Week/Page Grid with Task List template
            </Link>{' '}
            generates a calendar showing 7 days on a single page, arranged in a 2x3 grid layout. Each event will be ordered based on its starting time. All-day events will be placed at the top of each day cell. Tasks that are overdue or due within the selected week will be listed to the right of the day grid.
         </p>
      ),
      image: Screenshot1WeekPageGridWithTaskList,
   },
	{
		title: '2 Weeks/Page Grid',
		description: (
			<p>
				The{' '}
				<Link to="/Documentation/Create-Calendar/Templates-and-Options/Week-2-Weeks-Page">
					2 Weeks/Page Grid template
				</Link>{' '}
				generates 2 weeks to a single page, split down the middle with one week
				on the left and one on the right. The page can be cut in half and placed
				in a binder.
			</p>
		),
		image: Screenshot2WeeksPageGrid,
	},
   {
      title: 'Year - 2 Years/Page',
      description: (
         <p>
            The{' '}
            <Link to="/Documentation/Create-Calendar/Templates-and-Options/Year-2-Per-Page">
               2 Years/Page template
            </Link>{' '}
            generates a calendar showing 2 years on a single page. The years will be displayed side-by-side or stacked vertically. Each day can be color-coded based on the first event or task occurring on that date.
         </p>
      ),
      image: Screenshot2Years,
   },
   {
      title: 'Year - 3 Years/Page',
      description: (
         <p>
            The{' '}
            <Link to="/Documentation/Create-Calendar/Templates-and-Options/Year-3-Per-Page">
               2 Years/Page template
            </Link>{' '}
            generates a calendar showing 3 years on a single page. The years will be displayed side-by-side or stacked vertically. Each day can be color-coded based on the first event or task occurring on that date.
         </p>
      ),
      image: Screenshot3Years,
   },
   {
      title: 'Year - 4 Years/Page',
      description: (
         <p>
            The{' '}
            <Link to="/Documentation/Create-Calendar/Templates-and-Options/Year-4-Per-Page">
               2 Years/Page template
            </Link>{' '}
            generates a calendar showing 4 years on a single page, in a 2x2 grid. Each day can be color-coded based on the first event or task occurring on that date.
         </p>
      ),
      image: Screenshot4Years,
   },
];
