import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../containers/SaasModern/Documentation';
import create_calendar_choose_template from '../../../../../../common/src/assets/image/create_calendar_choose_template.png';
import create_calendar_example_carousel from '../../../../../../common/src/assets/image/create_calendar_example_carousel.png';
import create_calendar_target_excel from '../../../../../../common/src/assets/image/create_calendar_target_excel.png';
import create_calendar_target_word from '../../../../../../common/src/assets/image/create_calendar_target_word.png';
import create_calendar_target_powerpoint from '../../../../../../common/src/assets/image/create_calendar_target_powerpoint.png';
import create_calendar_context_menu from '../../../../../../common/src/assets/image/create_calendar_context_menu.png';
import TemplateSlider from '../../../../containers/SaasModern/TemplateSlider';

const Documentation = () => {
  const content = (
    <div>
      <p>
        Choosing a template is the first step when creating a printable
        calendar. PrintableCal includes over 90 different calendar templates.
        Additional templates can also be{' '}
        <Link to="/Documentation/How-To/Customize-a-Template">
          created and customized
        </Link>
        . Examples of the output generated using some of the calendar templates
        can be seen below.
      </p>
      <div style={{ marginTop: 16 }}>
        <TemplateSlider />
      </div>
      <h2>Template List</h2>
      <p>
        The installed templates will be listed in the Task Pane and grouped by
        type. To expand a group, click its header. To select a template from a
        group, click the template's name. The selected template will be
        highlighted in blue and the <strong>Next</strong> button will become
        enabled.
      </p>
      <p>
        <Image
          alt=""
          src={create_calendar_choose_template}
          style={{ width: 443 }}
        />
      </p>
      <h2>Show Example Carousel</h2>
      <p>
        A template example carousel window can be shown by checking the{' '}
        <strong>Show example carousel</strong> box located above the template
        list. The example carousel will appear to the left of the template list.
        It will show a thumbnail for the selected template. A thumbnail can be
        added to a template when{' '}
        <Link to="/Documentation/How-To/Customize-a-Template">customizing</Link>
        .{' '}
        <span>
          Use the left and right arrow buttons to preview each of the available
          templates.{' '}
        </span>
      </p>
      <p>
        <span>
          <Image alt="" src={create_calendar_example_carousel} />
          <br />
        </span>
      </p>
      <p>
        The example carousel window can be moved by dragging it with the mouse.
        It can be closed by clicking the round X button in the upper-right
        corner, or by unchecking the <strong>Show example carousel </strong>box.
        To select a template, click the Select button, or click the{' '}
        <strong>Next</strong> button below the template list in the PrintableCal
        task pane.
      </p>
      <h2>Target (Excel)</h2>
      <p>
        <Image
          alt=""
          src={create_calendar_target_excel}
          style={{ width: 448 }}
        />
      </p>
      <p>
        When using Excel, the calendar can be generated to the current workbook
        or a new workbook. The target can be selected below the template list.
        When <strong>Current workbook</strong> is selected, the generated
        calendar will be added as a new worksheet to the currently active
        workbook.
      </p>
      <h2>Target (Word)</h2>
      <p>
        <Image
          alt=""
          src={create_calendar_target_word}
          style={{ width: 447 }}
        />
      </p>
      <p>
        When using Word, the calendar can be generated to the current document
        or a new document. As with Excel, the target can be selected below the
        template list. When <strong>Current document</strong> is selected, the
        generated calendar will be added to the current document, starting at
        the current location of the text cursor.{' '}
      </p>
      <p>
        There is also an <strong>Format </strong>field. Available choices are{' '}
        <strong>Excel Worksheet Object</strong>,{' '}
        <strong>Formatted Text (RTF)</strong>, <strong>Picture</strong>, and{' '}
        <strong>HTML</strong>. Depending on the template, some formats may work
        better than others. For example, the Agenda template works best using
        the HTML format, which can span multiple pages. The Excel Worksheet
        Object format is limited to one page, but works better for templates
        that are designed to fit on one page, such as the default Month
        template.
      </p>
      <h2>Target (PowerPoint)</h2>
      <p>
        <Image
          alt=""
          src={create_calendar_target_powerpoint}
          style={{ width: 448 }}
        />
      </p>
      <p>
        When using PowerPoint, the calendar can be generated to the current
        slide or inserted on a new slide. The target can be selected below the
        template list. The generated calendar will be added as a picture.
      </p>
      <h2>Context Menu</h2>
      <p>
        The right-click menu provides a shortcut to other related functionality.
        To access this menu, right-click on one of the listed templates or click
        the menu button to the right of the template's name.
      </p>
      <p>
        <Image
          alt=""
          src={create_calendar_context_menu}
          style={{ width: 453 }}
        />
      </p>
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Customize</strong>
            </td>
            <td>
              Opens the template allowing it to be customized.{' '}
              <Link to="/Documentation/How-To/Customize-a-Template">
                Click here for a walkthrough
              </Link>
              .
            </td>
          </tr>
          <tr>
            <td>
              <strong>Add to Favorites</strong>
            </td>
            <td>
              Adds the template to a <strong>Favorites </strong>group shown at
              the top of the template list. This group can be used to easily
              access templates that are frequently used.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Remove Template</strong>
            </td>
            <td>Removes the template from the list.</td>
          </tr>
          <tr>
            <td>
              <strong>Reset to Original</strong>
            </td>
            <td>
              If the template was customized and overwrote a template that was
              provided with the PrintableCal installation, this menu command
              will reset the template back to the factory default.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Export</strong>
            </td>
            <td>
              Exports the template to a file that can be imported on other
              computers.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Choose-a-Template"
      commentsId="commentsplus_post_125_489"
      title="Step 1: Select a Template"
      description="Choosing a template is the first step when generating a printable calendar. PrintableCal includes a number of default templates. Additional templates can also be downloaded."
      keywords="calendar template, template list, printable calendar, month template, 4 day template, agenda template, Gantt Chart, week template, year template, Excel calendar"
      content={content}
    />
  );
};

export default Documentation;
