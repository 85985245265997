import React from 'react';
import 'rc-tabs/assets/index.css';
import Text from 'reusecore/src/elements/Text';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import { SCREENSHOTS } from 'common/src/data/SaasModern';
import Paper from '@material-ui/core/Paper';

const TemplateSlider = () => {
  const params = {
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
      loadOnTransitionStart: true,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: false,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  return (
    <Paper style={{ padding: 24 }} elevation={8}>
      <Swiper {...params}>
        {SCREENSHOTS.map((item, index) => (
          <div key={index}>
            <Text
              as="h3"
              content={`${item.title} Template`}
              style={{
                textAlign: 'left',
                fontSize: 24,
                fontFamily: ['Google Sans', 'sans-serif'],
                fontWeight: '500',
                marginTop: 0,
              }}
            />
            {item.description}
            <div style={{ textAlign: 'center', background: 'white' }}>
              <img
                data-src={item.image}
                className="swiper-lazy"
                style={{ maxHeight: 800, maxWidth: '100%' }}
                alt={`${item.title} Template`}
              />
            </div>
            <div className="swiper-lazy-preloader" />
          </div>
        ))}
      </Swiper>
    </Paper>
  );
};

export default TemplateSlider;
